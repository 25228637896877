<template>
<div>
  <div class="headTop"><HeadTop /></div>
  <div class="main">
    <div class="title">{{mess.title}}</div>
    <div class="second-title">{{mess.subTitle}}</div>
    <div class="sub-title">
      <span class="sub-date">{{format.format(mess.createDate)}}</span>
    </div>
    <div class="content" v-html="mess.content">
    </div>
  </div>
</div>
</template>

<script>
import format from '@/utils/format.js'
import axios from "axios"
import HeadTop from '@/components/HeadTop.vue'
export default {
  name: 'consultingDetail',
  data(){
    return {
      format,
      mess:''
    }
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      const param = {
        id: JSON.parse(sessionStorage.getItem('zxDetail')).id
      }
      let _this = this
      axios({
        url: this.$baseUrl + 'official/news/getNewsInfo',
        method: 'POST',
        data: param
      }).then( res => {
        if(res && res.data&&res.data.success){
          _this.mess = res.data.resultData
        }
      })
    }
  },
  components: {
    HeadTop
  },
}
</script>

<style lang="less" scoped>
  .headTop{
    width: 1140px;
    margin: 0 auto;
  }
  .main{
    width: 1140px;
    min-height: 600px;
    background: #fff;
    margin: auto;
    padding: 50px;
    margin: 0px auto;    
    margin-bottom: 60px;
    .title{
      height: 30px;
      font-size: 22px;
      font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
      font-weight: bold;
      color: #000;
      line-height: 30px;
      text-align: center;
    }
    .second-title{
      margin-top:20px;
      font-size: 18px;
      font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
      color: #000000a6;
      line-height: 30px;
      text-align: center;
    }
    .sub-title{
      font-size: 14px;
      text-align: center;
      display: flex;
      margin-top: 10px;
      .sub-date{
        margin-left: auto;
      }
    }
    .content {
      margin-top: 20px;
    }
  }
</style>